



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.main_attributes_wrapper_container {
  .add_attributes_dialog_wrapper {
    .el-dialog {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90% !important;
      }
    }
  }
}
